import React, { useCallback, useEffect, useState } from "react";
import * as JsSearch from "js-search";
import { graphql } from "gatsby";
import { useSelector } from "react-redux";
import { uniqBy } from "lodash";

import Hero from "../components/Templates/Hero";
import Image from "../components/Global/Image";
import Link from "../components/Global/Link";
import Layout from "../components/Templates/Layout";
import Meta from "../utils/Meta";
import Background from "../components/Global/Background";
import Sections from "../components/Sections/Sections";

const SearchResults = (props) => {
  const { data, blok, pageContext } = props;

  let doc;
  if (pageContext) {
    doc = JSON.parse(pageContext.story.content);
  } else {
    doc = blok;
  }
  const openGraph = {
    type: "website",
    title: doc?.title,
    description: doc?.body,
  };

  const searchQuery = useSelector((state) => state.search.searchQuery);

  const searchableKeys = [
    "arcadeMachines",
    "categories",
    "managementSolutions",
    "prizes",
    "homeProducts",
  ];
  const mappedProductsSearchData = Object.keys(data || {}).reduce(
    (currList, dataItem) => {
      if (!searchableKeys.includes(dataItem)) {
        return currList;
      }

      const mappedDataItems = data[dataItem]?.nodes?.map((node) => {
        const parsedNodeContent = JSON.parse(node.content);

        return {
          full_slug: node?.full_slug,
          tags: node?.tag_list?.join(" "),
          ...parsedNodeContent,
        };
      });

      currList.push(...mappedDataItems);

      return currList;
    },
    []
  );

  const mappedPagesSearchData = data?.pages?.edges?.reduce(
    (currList, dataItem) => {
      const node = dataItem.node;

      const parsedNodeContent = JSON.parse(node?.content);

      currList.push({
        ...node,
        tags: node?.tag_list?.join(" "),
        ...parsedNodeContent,
      });

      return currList;
    },
    []
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [productsDataList] = useState(mappedProductsSearchData);
  const [pagesDataList] = useState(mappedPagesSearchData);
  const [productsSearchResults, setProductsSearchResults] = useState([]);
  const [pagesSearchResults, setPagesSearchResults] = useState([]);
  const [productsSearch, setProductsSearch] = useState({});
  const [pagesSearch, setPagesSearch] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Add in loader if needed

  const rebuildProductsIndex = useCallback(() => {
    const dataToSearch = new JsSearch.Search("full_slug");
    /**
     * defines an indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("full_slug");
    dataToSearch.addIndex("title"); // sets the index attribute for the data
    dataToSearch.addIndex("tags"); // sets the index attribute for the data
    dataToSearch.addDocuments(productsDataList); // adds the data to be searched

    setProductsSearch(dataToSearch);
    setIsLoading(false);
  }, [productsDataList]);

  const rebuildPagesIndex = useCallback(() => {
    const dataToSearch = new JsSearch.Search("full_slug");
    /**
     * defines an indexing strategy for the data
     * more about it in here https://github.com/bvaughn/js-search#configuring-the-index-strategy
     */
    dataToSearch.indexStrategy = new JsSearch.PrefixIndexStrategy();
    /**
     * defines the sanitizer for the search
     * to prevent some of the words from being excluded
     *
     */
    dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
    /**
     * defines the search index
     * read more in here https://github.com/bvaughn/js-search#configuring-the-search-index
     */
    dataToSearch.searchIndex = new JsSearch.TfIdfSearchIndex("full_slug");
    dataToSearch.addIndex("title"); // sets the index attribute for the data
    dataToSearch.addIndex("body"); // sets the index attribute for the data
    dataToSearch.addIndex("tags"); // sets the index attribute for the data
    dataToSearch.addDocuments(pagesDataList); // adds the data to be searched

    setPagesSearch(dataToSearch);
    setIsLoading(false);
  }, [pagesDataList]);

  const performProductsSearch = useCallback(() => {
    if (productsSearch.search) {
      let queryResultList = [];

      // If we have a searchQuery
      if (searchQuery?.length) {
        // Search for the searchQuery entered by the user
        const searchQueryWords = searchQuery.replace("’", "'").split(" ");
        searchQueryWords.forEach((word) => {
          queryResultList.push(...productsSearch?.search(word));

          [...word].forEach((char, index) => {
            const concatenatedSearchTerm =
              word.slice(0, index || 1) +
              "-" +
              word.slice(index === 0 ? index + 1 : index);
            queryResultList.push(
              ...productsSearch?.search(concatenatedSearchTerm)
            );
          });
        });
        queryResultList = uniqBy(queryResultList, "full_slug");
      }

      setProductsSearchResults(queryResultList);
    }
  }, [productsSearch, searchQuery]);

  const performPagessSearch = useCallback(() => {
    if (pagesSearch.search) {
      let queryResultList = [];

      // If we have a searchQuery
      if (searchQuery?.length) {
        const searchQueryWords = searchQuery.replace("’", "'").split(" ");
        searchQueryWords.forEach((word) => {
          queryResultList.push(...pagesSearch?.search(word));

          [...word].forEach((char, index) => {
            const concatenatedSearchTerm =
              word.slice(0, index || 1) +
              "-" +
              word.slice(index === 0 ? index + 1 : index);
            queryResultList.push(
              ...pagesSearch?.search(concatenatedSearchTerm)
            );
          });
        });
        queryResultList = uniqBy(queryResultList, "full_slug");
      }

      setPagesSearchResults(queryResultList);
    }
  }, [pagesSearch, searchQuery]);

  // Rebuild index on page render
  useEffect(() => {
    rebuildPagesIndex();
    rebuildProductsIndex();
  }, [rebuildPagesIndex, rebuildProductsIndex]);

  // When search object changes, try perform the search
  useEffect(() => {
    performPagessSearch();
    performProductsSearch();
  }, [performPagessSearch, performProductsSearch]);

  // Define template for search results
  const mappedProductsSearchResults = productsSearchResults?.map(
    (sResult, index) => {
      return (
        <div key={index} className="col">
          <Link
            key={index}
            to={sResult.full_slug}
            className="d-flex flex-column justify-content-between brand-text-white"
          >
            <Image
              className="rounded brand-background-white"
              src={sResult.product?.filename}
              alt={sResult.product?.alt}
              sm="300x300"
              md="400x400"
              lg="600x600"
            />
            <p
              className="
                        brand-font-size-14 brand-font-size-lg-21
                        brand-font-weight-400
                        brand-line-height-40
                        brand-font
                        brand-search-result-title
                        brand-text-white"
            >
              {sResult.title}
            </p>
          </Link>
        </div>
      );
    }
  );

  const mappedPagesSearchResults = pagesSearchResults?.map((sResult, index) => {
    return (
      <div key={index} className="col-12">
        <Link
          key={index}
          to={sResult.full_slug}
          className="d-flex flex-column justify-content-between brand-text-white"
        >
          <Background
            className="rounded brand-background-purple d-flex justify-content-center justify-content-lg-start align-items-center"
            src={sResult.image?.filename || sResult.hero?.filename}
            alt={sResult.image?.alt || sResult.hero?.alt}
            sm="500x150"
            md="900x150"
            lg="1920x150"
          >
            <p
              className="
                        ms-4
                        my-auto
                        py-2
                        brand-font-size-24 brand-font-size-lg-32
                        brand-font-weight-400
                        brand-line-height-40
                        brand-font
                        brand-text-white"
            >
              {sResult.title}
            </p>
          </Background>
        </Link>
      </div>
    );
  });

  const title = (
    <p className="brand-text-white">
      Search results for{" "}
      <span className="brand-text-pink">"{searchQuery}"</span> (
      {productsSearchResults?.length + pagesSearchResults?.length})
    </p>
  );

  const parsedSiteSettings = data?.settings
    ? JSON.parse(data?.settings.content)
    : {};

  return (
    <Layout>
      <Meta openGraph={openGraph} meta={doc.meta} />

      {isLoading && (
        <div className="container-fluid brand-background-white brand-text-black">
          <div className="container py-5">
            <div className="spinner-grow" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}

      {!isLoading && (
        <React.Fragment>
          <Hero title={title} background={doc.image?.filename} />

          <div className="container-fluid brand-background-clip brand-background-purple brand-text-white">
            <div className="container py-5">
              <div className="row">
                <h5 className="text-uppercase brand-font-size-40 brand-font-weight-400 brand-line-height-48 brand-font">
                  Products ({productsSearchResults.length})
                </h5>
                <div className="row justify-content-start row-cols-1 row-cols-md-3 gy-4">
                  {mappedProductsSearchResults}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid brand-background-white brand-text-black">
            <div className="container py-5">
              <div className="row">
                <h5 className="text-uppercase brand-font-size-40 brand-font-weight-400 brand-line-height-48 brand-font brand-text-pink">
                  Pages ({pagesSearchResults.length})
                </h5>
                <div className="row justify-content-start gy-4">
                  {mappedPagesSearchResults}
                </div>
              </div>
            </div>
          </div>

          <Sections
            items={doc?.sections}
            settings={parsedSiteSettings}
            notfirst
          />
        </React.Fragment>
      )}
    </Layout>
  );
};

export default SearchResults;

export const QUERY = graphql`
  query SearchResultsPageQuery {
    pages: allStoryblokEntry(
      filter: {
        field_component: {
          in: [
            "contact"
            "default"
            "products"
            "product_categories"
            "manufacturer"
            "search_results"
            "support"
          ]
        }
      }
      sort: { fields: field_date_string, order: ASC }
    ) {
      edges {
        node {
          field_component
          field_date_string
          full_slug
          lang
          content
          tag_list
        }
      }
    }

    arcadeMachines: allStoryblokEntry(
      filter: { field_component: { in: ["arcade_machine"] } }
    ) {
      nodes {
        full_slug
        content
        tag_list
      }
    }

    homeProducts: allStoryblokEntry(
      filter: { field_component: { in: ["home_product"] } }
    ) {
      nodes {
        full_slug
        content
        tag_list
      }
    }

    prizes: allStoryblokEntry(filter: { field_component: { in: ["prize"] } }) {
      nodes {
        full_slug
        content
        tag_list
      }
    }

    managementSolutions: allStoryblokEntry(
      filter: { field_component: { in: ["management_solution"] } }
    ) {
      nodes {
        full_slug
        content
        tag_list
      }
    }

    settings: storyblokEntry(slug: { eq: "settings" }) {
      content
    }
  }
`;
